import React, { useState } from "react";
import ScannDeviceQR from "../scanDeviceQr/scanDeviceQrModal";
import axios from "../../axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import "../homePage/homepage.scss"
import { useNavigate } from 'react-router-dom';
import userImage from "../../images/829466_man_512x512-removebg-preview.png"
import {
  loggMeIN,
  rmAuth,
  setActiveMenu,
  toggleMenu,
} from "../../redux/Functions/actions";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { Pagination } from "../modalStyle/modalStyle";


const ActivateUsers = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [writtenDeviceId, setWrittenDeviceId] = useState(null);
  const [scanedDeviceId, setScanedDeviceId] = useState(null);
  const [foundUser, setFoundUser] = useState(null);
  const [deviceIdToShow, setDeviceIdToShow] = useState(null);
  const [selectedPacket, setSelectedPacket] = useState();
  const [poackedId, setPackedId] = useState();
  const [selectedTrainerID, setSelectedTrainerID] = useState();
  const [selectedTrainer, setSelectedTrainer] = useState();
  const [packages, setPackages] = useState([]);
  const [personalTrainers, setPersonalTrainers] = useState([]);
  const [users, setUsers] = useState();
  const [filtered, setFiltered] = useState([]);

    //Pagination
    const [pageNumber, setPageNumber] = useState(0);
    const productsPerPage = 8;
    const pagesVisited = pageNumber * productsPerPage;
    const pageCount = Math.ceil(filtered.length / productsPerPage);
    const changePage = ({ selected }) => {
      setPageNumber(selected);
    }

  const navigate = useNavigate();
  console.log("foundUser", foundUser)
  const handleModalAdd = () => {
    setIsOpen(true);
  };

  const handleClick = () => {
    navigate('/');
  };

  const handleUserSearch = (deviceID) => {
    axios.get(`/users/search-by-device/${deviceID}`)
      .then(res => {
        console.log("res?.data", res?.data);
        setFoundUser(res?.data);
      })
      .catch(err => console.error(err));
  };

  const receiveItemsFromChild = (childItems) => {
    setScanedDeviceId(childItems);
    handleUserSearch(childItems);
  };
  // const handleUserSearch = (e) => {
  //   e.preventDefault()
  //   axios.get(`/users/search-by-device/${writtenDeviceId || scanedDeviceId}`)
  //     .then(res => { console.log("res?.data", res?.data); setFoundUser(res?.data) })
  //     .catch(err => console.error(err))
  // };
  const handleGetPackages = () => {
    axios.get(`/packages/all`)
      .then(res => setPackages(res?.data))
      .catch(err => console.error(err))
  };
  const handleGetPA = () => {
    axios.get(`/personal-trainers/all`)
      .then(res => setPersonalTrainers(res?.data))
      .catch(err => console.error(err))
  };

  const handlePostSubscription = (e) => {
    e.preventDefault()
    let body = {
      subscription_id: poackedId,
      user_id: foundUser?.user?.id,
      email: foundUser?.user?.email,
      personal_trainer_id: selectedTrainerID,
      fitness_id: props?.fitness_id
    }
    axios.post(`/packages/subscribe`, body)
      .then((res) => {
        toast.success("Submit successfully!");
        setSelectedPacket(null);
        setPackedId(null);
        setSelectedTrainerID(null);
        setSelectedTrainer(null);
        setFoundUser(null);
        setWrittenDeviceId(null);
        setScanedDeviceId(null);
      })
      .catch(err => console.error(err))
  };

  const getUsers = () => {
    axios.get(`/packages/userSubscriptions/cash/${props?.fitness_id}`).then(data => {
      console.log("test", data)
      setUsers(data?.data);
      setFiltered(data?.data);
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    handleGetPackages()
    handleGetPA();
    getUsers();
  }, [])


  console.log("ola", users)

  return (
    <>
      {isOpen === true && (<ScannDeviceQR
        setScanedDeviceId={receiveItemsFromChild} isOpen={isOpen} handleModalClose={() => { setIsOpen(); }} />)}
      <div className="backButton p-3">
        <button onClick={handleClick}><i class="fa-solid fa-arrow-left"></i>
          &nbsp; Back</button>
      </div>
      <div className="data min-vh-100 d-flex  justify-content-center ">
        {console.log("foundUser", foundUser?.user)}
        <div className="container">
          <div className="card p-3">
            <div className="card-body">
              <h3 className="">Search for user by Device ID</h3>
              <form >
                <div className="row">
                  {/* <div className=""> */}
                  {/* <input
                      className="form-control me-2"
                      type="search"
                      placeholder="Enter Device ID: 12345678"
                      aria-label="Search"
                      onChange={(e) => { e?.preventDefault(); setWrittenDeviceId(e.target.value) }}
                      defaultValue={scanedDeviceId || null}
                      value={writtenDeviceId || scanedDeviceId} /> */}

                  {/* </div> */}
                  <div className="scanDevice mt-1">
                    <div className="mt-2">
                      <h6>
                        Scan Device ID
                      </h6>
                    </div>
                    &nbsp;

                    <button type="button" className="buttons"
                      onClick={() => { handleModalAdd(); }}
                    >
                      Scan <i class="fa-solid fa-qrcode"></i>
                    </button>
                    &nbsp;
                    {/* <button className="searchButton" type="submit" onClick={(e) => handleUserSearch(e)}> <i class="fa-solid fa-magnifying-glass"></i> </button> */}
                  </div>
                  <div className="col-12 mt-5">
                    {console.log("foundUser", foundUser)}

                    {foundUser === null ?
                      <>
                        <div className="divNoUser">
                          {/* <p className="noUserFoundParagraf">No User found with this ID</p> */}
                        </div>
                      </>
                      :
                      <>
                        <p className="fw-bold">User Found</p>
                        <div className="row">
                          <div className="col-md-7 col-lg-6 col-12 userCard">
                            <div className="row cardLayout ">
                              <div className="col-12 col-md-6 divCardImage">
                                <div className="cardImage">
                                  <img src={userImage} alt="" />
                                </div>
                              </div>
                              <div className="col-12 col-lg-6 col-md-12 deviceUserId mt-3 ">
                                <p className="mb-4"><b>{foundUser?.user?.first_name}</b> </p>
                                <p>  <i class="fa-regular fa-envelope" style={{ color: 'black' }}></i> <b> Email: </b>  {foundUser?.user?.email}</p>
                                {/* <p>  <i class="fa-regular fa-calendar" style={{ color: 'black' }}></i> <b> Date of Birth: </b>  <br /> {foundUser?.user?.dob}</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  </div>
                  {foundUser !== null &&
                    <div className="row m-0 p-0 mt-2">
                      <div className="col-md-10 col-12 mt-2 buttonGroup">
                        <div class="">
                          <button type="button" class="btn btn-dark dropdown-toggle buttonDropDown" data-bs-toggle="dropdown" aria-expanded="false">
                            {selectedPacket || 'Activate Package'} </button>
                          <ul class="dropdown-menu">
                            {packages?.map(el =>
                              <li> <a onClick={() => { setSelectedPacket(`${el?.month} Months ${el?.price}€`); setPackedId(el?.id) }} class="dropdown-item" href="#">{el?.month} Months {el?.price}€</a></li>
                            )}
                          </ul>
                        </div>
                        <div class="">
                          <button type="button" class="btn btn-dark dropdown-toggle buttonDropDown" data-bs-toggle="dropdown" aria-expanded="false">
                            {selectedTrainer || 'Select Trainer'}</button>
                          <ul class="dropdown-menu">
                            {personalTrainers?.map(el =>
                              <li> <a onClick={() => { setSelectedTrainer(`${el?.first_name} ${el?.last_name}`); setSelectedTrainerID(el?.id) }} class="dropdown-item" href="#">{el?.first_name} {el?.last_name}  </a></li>
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-2 col-12 mt-2 submitButton">
                        <button className="buttonSubmitLast" disabled={!selectedPacket || !selectedTrainer} type="submit" onClick={(e) => handlePostSubscription(e)}> Submit </button>
                      </div>
                    </div>
                  }
                </div>
              </form>
              <div>
                <div class="table-responsive mt-2">
                  <table class="table table-hover">
                    <thead className="table-dark">
                      <tr>
                        <th scope="col">Emri</th>
                        <th scope="col">Email</th>
                        <th scope="col">Paketa</th>
                        <th scope="col">Emri i Trajnerit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka të dhëna</h6></div>}
                      {filtered && filtered?.length > 0 && <>
                        {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                          return (
                            <>
                              <tr>
                                <th scope="row">{el?.userInfo?.first_name} {el?.userInfo?.last_name}</th>
                                <td >{el?.userInfo?.email}</td>
                                <td>{el?.package?.name_al}</td>
                                <td>{el?.personalTrainerInfo?.first_name} {el?.personalTrainerInfo?.last_name}</td>
                              </tr>
                            </>
                          )
                        })}
                      </>}
                    </tbody>
                  </table>
                  <Pagination>
                    <div className="text-right">
                      {((users?.length >= productsPerPage) ?
                        <ReactPaginate
                          previousLabel={<i className='fa fa-angle-double-left' />}
                          nextLabel={<i className='fa fa-angle-double-right' />}
                          pageCount={pageCount}
                          onPageChange={changePage}
                          containerClassName={"paginationBttns"}
                          previousLinkClassName={"previousBttn"}
                          nextLinkClassName={"nextBttn"}
                          disabledClassName={"paginationDisabled"}
                          activeClassName={"paginationActive"}
                        /> : ''
                      )}
                    </div>
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    fitness_id: state.data.fitness_id,
    langData: state.data.langData,
    activemenu: state.data.activemenu,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
    // loadingOff: () => dispatch(loadingOff()),
    setActiveMenu: (id) => dispatch(setActiveMenu(id)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ActivateUsers);
