import {
    SET_AUTH,
    RM_AUTH,
    LANG_AL,
    LANG_EN,
    LOADING_ON,
    LOADING_OFF,
    LOGGEDIN,
    USER_ID,
    ROLE,
    MENU,
    FITNESS_ID,
    ACTIVEMENU
} from './types';


const INITIAL_STATE = {
    lang: 'en',

    loggedIn: false,
    auth: '',
    user_id: null,
    fitness_id: null,
    role: null,
    menu: true,
    activemenu: 1
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LANG_AL:
            return { ...state, lang: 'al', };
        case LANG_EN:
            return { ...state, lang: 'en', };
        case LOADING_ON:
            return { ...state, isLoading: true };
        case LOADING_OFF:
            return { ...state, isLoading: false };
        case LOGGEDIN:
            if (state.loggedIn === false) {
                return { ...state, loggedIn: true }
            } else
                return { ...state, loggedIn: false };
        case SET_AUTH:
            return { ...state, auth: action.auth };
        case RM_AUTH:
            return { ...state, auth: '' };
        case USER_ID:
            return { ...state, user_id: action.user_id };
        case FITNESS_ID:
            return { ...state, fitness_id: action.fitness_id };
        case ROLE:
            return { ...state, role: action.role };
        case MENU:
            return { ...state, menu: !state.menu };
        case ACTIVEMENU:
            return { ...state, activemenu: action.activemenu }
        default: return state;
    }
};
export default reducer;