import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import HomePage from "./components/homePage/homePage";
import DayPass from "./components/1DayPass/1DayPass";
import ActivateUsers from "./components/actUsers/actUsers";
import Login from "./components/logIn/login";
import { connect } from "react-redux";
import { loggMeIN, rmAuth, toggleMenu } from "./redux/Functions/actions";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App(props) {
  return (
    <>
      <ToastContainer position="bottom-right" theme="colored" style={{ zIndex: '99999999999999999999999999' }} />
      <BrowserRouter>
        {props?.loggedIn === false && props?.auth == "" ? (
          <Routes>
            <Route path="/*" element={<Login />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/1DayPass" element={<DayPass />} />
            <Route path="/activateUsers" element={<ActivateUsers />} />
          </Routes>
        )}
      </BrowserRouter>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    auth: state.data.auth,
    lang: state.data.lang,
    langData: state.data.langData,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
    // loadingOff: () => dispatch(loadingOff()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
