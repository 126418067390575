import React, { useEffect, useState } from "react";
import DayPassModal from "./1dayPassModal";
import { Pagination } from "../modalStyle/modalStyle";
import axios from "../../axios"
import { connect } from "react-redux";
import { loggMeIN, rmAuth, toggleMenu } from "../../redux/Functions/actions";
import ReactPaginate from "react-paginate";
import Search from "../search/search";
import { useNavigate } from 'react-router-dom';


const DayPass = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState();
  const [filtered, setFiltered] = useState([]);

  const navigate = useNavigate();


  const handleClick = () => {
    // Use navigate to go to another page
    navigate('/');
  };

  //Pagination
  const [pageNumber, setPageNumber] = useState(0);
  const productsPerPage = 8;
  const pagesVisited = pageNumber * productsPerPage;
  const pageCount = Math.ceil(filtered.length / productsPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  }
  const handleModalAdd = () => {
    setIsOpen(true);
  };
  const getUsers = () => {
    axios.get(`/day-passes/${props?.fitness_id}`).then(data => {
      setUsers(data.data);
      setFiltered(data?.data);
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(()=>{
    getUsers();
  },[])
console.log('filtered',filtered)
  return (
    <>
      {isOpen === true && (<DayPassModal getUsers={() => getUsers()} isOpen={isOpen} handleModalClose={() => {setIsOpen();}}/>)}
            <div className="backButton p-3">
                   
              <button onClick={handleClick}><i class="fa-solid fa-arrow-left"></i> 
               &nbsp; Back</button>
              </div>
      <div className="data min-vh-100 d-flex align-items-start justify-content-center ">
       <div className="container">
        {/* <Search data={users} filtered={setFiltered} setPageNumber={setPageNumber} /> */}
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <h3>1 Day Pass</h3>
                <div className="d-flex justify-content-end">
                  <div className=" p-1">
                    {/* <button type="button"  className="buttons" onClick={() => {handleModalAdd();}}>
                      Scan Qr
                    </button> */}

                    <button type="button" className="buttons"
                      onClick={() => { handleModalAdd(); }}
                    >
                      Scan <i class="fa-solid fa-qrcode"></i>
                    </button>


                  </div>
                </div>
              </div>
              <div class="table-responsive mt-2">
                <table class="table table-hover">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">#ID</th>
                      <th scope="col">User</th>
                      <th scope="col">Status</th>
                      <th scope="col">Device ID</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                  {filtered && filtered?.length === 0 && <div className='text-secondary text  m-3'><h6>Nuk ka të dhëna</h6></div>}
                  {filtered && filtered?.length > 0 && <>
                  {filtered?.slice(pagesVisited, pagesVisited + productsPerPage)?.map((el, index) => {
                        return (
                          <>
                            <tr>
                              <th scope="row">{el?.id}</th>
                              <td >{el?.user?.first_name} {el?.user?.last_name}</td>
                              <td> Used </td>
                              <td>{(el?.device_id)}</td>
                              <td>{(el?.created_at)?.slice(0,10)}</td>
                            </tr>
                            </>
                        )})}
                        </>}
                  </tbody>
                </table>
                <Pagination>
                <div className="text-right">
                {((users?.length >= productsPerPage) ?
                  <ReactPaginate
                    previousLabel={<i className='fa fa-angle-double-left' />}
                    nextLabel={<i className='fa fa-angle-double-right' />}
                    pageCount={pageCount}
                    onPageChange={changePage}
                    containerClassName={"paginationBttns"}
                    previousLinkClassName={"previousBttn"}
                    nextLinkClassName={"nextBttn"}
                    disabledClassName={"paginationDisabled"}
                    activeClassName={"paginationActive"}
                  /> : ''
                )}
              </div>
            </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    langData: state.data.langData,
    fitness_id: state.data.fitness_id,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
    // loadingOff: () => dispatch(loadingOff()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DayPass);