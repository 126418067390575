import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
} from "../modalStyle/modalStyle";
import ScanImage from "../../images/scanqr.jpg";
import axios from "../../axios";
import { connect } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import { QrReader } from "react-qr-reader";


const DayPassModal = (props) => {
  const [isOpenScan, setIsOpenScan] = useState(false);
  const [carchData, setCarchData] = useState(false);
  const [dayPassData, setDayPassData] = useState(null);
  const [qrCode, setQrCode] = useState(null);
  const [facingMode, setFacingMode] = useState('environment'); // 'user' or 'environment'
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleScan = async (scanData) => {
    if (scanData?.text !== undefined) {
      setIsOpenScan(false);
      setDayPassData(null);
      setCarchData(true);
      setQrCode(scanData?.text);
      let device_id = String(scanData?.text);
      try {
        const res = await axios.post(`/one_day_pass/find-one/${device_id}/${props?.fitness_id}`);
        console.log('Full Server Response:', res);
        if (res.data) {
          setDayPassData(res.data);
          props?.getUsers()
        } else {
          console.error('Unexpected server response:', res);
        }
      } catch (err) {
        console.error('Axios Error:', err);
      }
    }
  };
  // useEffect(() => { props?.getUsers() }, [])

  const handleError = (error) => {
    console.error('Error scanning QR code:', error);
  };
  const constraints = {
    facingMode: isMobile ? 'environment' : 'user',
  };

  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper>
              <ModalActionButtonWrapper>
                <ModalButtons close onClick={() => props?.handleModalClose()}>
                  <i className={"fa fa-times"} />
                </ModalButtons>
              </ModalActionButtonWrapper>
              <Form>
                <ModalTitle title className="border-bottom border-4 rounded-3  border-danger">Skano QR  Kodin</ModalTitle>
                {isOpenScan === true ? (
                  <div className="card">
                    <div className="card-body codeReader">
                      <QrReader
                        delay={300}
                        style={{ width: '100%' }}
                        onScan={handleScan}
                        onError={handleError}
                        onResult={(result) => { handleScan(result) }}
                        constraints={constraints}
                      />
                      <div className="d-flex justify-content-end">
                        <div className="border border-2 rounded border-danger p-1 ">
                          <button type="button" className="buttons" onClick={() => { setIsOpenScan(!isOpenScan); }}>Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="scan d-grid">
                      <div className="card">
                        <div className="card-body">
                          <div className="row align-items-center">
                            <div className="col-12 d-flex justify-content-center">
                              <img className="w-50" src={ScanImage} />
                            </div>
                            <div className="col-12 text-center">
                              <div className="d-flex justify-content-center mt-3">
                                <div className=" p-1 ">
                                  <button type="button" className="buttons" onClick={() => { setIsOpenScan(!isOpenScan); setCarchData(false); }}>Skano</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {carchData === true &&
                  dayPassData?.user != null ?
                  <>
                    <div className="d-flex justify-content-center">
                      <h3 className="text-danger mt-3">1 Day pass është përdorur nga :</h3>
                    </div>
                    <div className="mt-4 mb-5 cardCode">
                      <p> <b> Emri:</b>  {dayPassData?.user?.first_name} {dayPassData?.user?.last_name}</p>
                      <p> <b> E-mail:</b>   {dayPassData?.user?.email} </p>
                      {/* <p> <b> Datëlindja: </b> {dayPassData?.user?.dob} </p> */}
                      {/* <p> <b> Device ID:</b>  {dayPassData?.user?.day_pass?.device_id} </p> */}
                      <p> <b> Data e përdorimit:</b>  {
                        new Date(dayPassData?.user?.day_pass?.created_at)?.toLocaleString('en-EU',
                          {
                            timeZone: 'UTC',
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                          })
                      } </p>
                    </div>
                  </>
                  :
                  carchData === true &&
                  <>
                    <h3 className="mt-5 text-success">1 Day pass Activated sucessfully for :                    </h3>
                    <p>Emri plot : {dayPassData?.userData?.first_name} {dayPassData?.userData?.last_name}</p>
                  </>

                }
              </Form>
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else { return null; }
};

// export default DayPassModal;
const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    fitness_id: state.data.fitness_id,
    langData: state.data.langData,
    activemenu: state.data.activemenu,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};


export default connect(mapStateToProps)(DayPassModal);