import styled, { css } from "styled-components";

export const AppWrapper = styled.div`
  /* position: fixed; */
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

/*  MODAL */
export const ModalStyle = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .28);
    z-index: 99999;
    width: 100%;
    height: 100%;
    form { margin: 0 auto; width: 80%; }
     a {
     height: 50px;
     width: 50px;
     border: unset;
     color: green;
     }
`;
export const Form = styled.form``;
export const ModalTitle = styled.h3`
  position: sticky;
  width: 80%;
  z-index: 99999;
  ${(props) => props?.title &&
    css` margin-bottom: 40px; `}
  ${(props) => props?.deleteMessage &&
    css` width: 100%; margin-top: 10%; `}
`;
export const Mymodal_wrapper = styled.div`
  position: absolute;
  right: 0;
  width: 50%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  filter: drop-shadow(0 0 0.75rem gray);
   background-color: white;
   z-index: 999999;
  @media only screen and (max-width: 750px) { width: 100%; }
`;
export const ModalActionButtonWrapper = styled.div`
  position: sticky;
  top: 0;
  right: 0;
  text-align: right;
  background-color: #fff;
  z-index: 99999;
`;
export const ModalButtons = styled.button`
  height: 50px;
  width: 50px;
  border: unset;
  color: white;
  ${(props) => props?.edit &&
    css` background-color: #ffc107; `}
  ${(props) => props?.delete &&
    css` background-color: #dc3545; `}
  ${(props) => props?.close &&
    css` background-color: #444444; `}
`;
export const SaveButtonModal = styled.button`
    background-color: #ED2024;
    margin-top:10px;
    margin-bottom: 10px;
    margin-left: 1px;
    color: #fff;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    width:100px;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,
    background-color .15s ease-in-out,
    border-color .15s ease-in-out,
    box-shadow .15s ease-in-out;  
`;
export const DeleteButtonModal = styled.button`
    width: 100px;
    background-color: #dc3545;
    color: #fff;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, 
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`;

// Pagination
export const Pagination = styled.div`
.paginationBttns {
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    @media only screen and (max-width: 750px) { justify-content: start; }
    a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid black;
    color: black;
    cursor: pointer;
    @media only screen and (max-width: 750px) { margin: 5px; }
    &:hover{ color: white !important; background-color: black; }
    }
   .paginationActive a { color: white !important; background-color: black; }
   .paginationDisabled a { display: none; }
  }`;