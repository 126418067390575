import React, { useEffect, useState } from "react";
import ModalPortal from "../../portal/ModalPortal";
import {
  ModalActionButtonWrapper,
  ModalStyle,
  Mymodal_wrapper,
  ModalButtons,
  Form,
  ModalTitle,
} from "../modalStyle/modalStyle";
import QrCodeReader from "react-qrcode-reader";
import ScanImage from "../../images/scanqr.jpg";
import axios from "../../axios";
import { connect } from "react-redux";
import { useMediaQuery } from 'react-responsive';
import { QrReader } from "react-qr-reader";

const DayPassModal = (props) => {
  const [isOpenScan, setIsOpenScan] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleScan = async (scanData) => {
    if (scanData?.text !== undefined) {
      console.log("Scanned data:", scanData);
      props?.setScanedDeviceId(String(scanData?.text))
      props?.handleModalClose()
      setIsOpenScan(false);
    }
    else { return null; }
  };

  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const handleError = (error) => {
    console.error('Error scanning QR code:', error);
  };
  const constraints = {
    facingMode: isMobile ? 'environment' : 'user',
  };


  if (props?.isOpen) {
    return (
      <>
        <ModalPortal>
          <ModalStyle>
            <div className="wholePage" onClick={() => props?.handleModalClose()} />
            <Mymodal_wrapper>
              <ModalActionButtonWrapper>
                <ModalButtons close onClick={() => props?.handleModalClose()}>
                  <i className={"fa fa-times"} />
                </ModalButtons>
              </ModalActionButtonWrapper>
              <Form>
                <ModalTitle title className="border-bottom border-4 rounded-3  border-danger">Skano Qr Kodin</ModalTitle>
                <div className="card">
                  <div className="card-body codeReader">
                    <QrReader
                      delay={300}
                      style={{ width: '100%' }}
                      onScan={handleScan}
                      onError={handleError}
                      onResult={(result) => { handleScan(result) }}
                      constraints={constraints}
                    />
                  </div>
                </div>
              </Form>
            </Mymodal_wrapper>
          </ModalStyle>
        </ModalPortal>
      </>
    );
  } else { return null; }
};

// export default DayPassModal;
const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    fitness_id: state.data.fitness_id,
    langData: state.data.langData,
    activemenu: state.data.activemenu,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};


export default connect(mapStateToProps)(DayPassModal);
