import React from 'react'
import HomeImage from "../../images/imagegym.jpg";
import "./homepage.scss"

const FitnessSlider = (props) => {
    console.log('slider props', props)
    let images = [];
    props?.fitnessGallery?.forEach(element => {
        images.push(`${process.env.REACT_APP_UPLOADS}${element?.image}`)
    });
    console.log('images', images)
    return (
        <div id="carouselExampleCaptions" class="carousel slide sliderStyle" data-bs-ride="carousel">
            <div class="carousel-indicators">
            {images?.map((el, index) => (
                    <button
                        key={index}
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide-to={index}
                        className={index === 0 ? 'active' : ''}
                        aria-current={index === 0 ? 'true' : 'false'}
                        aria-label={`Slide ${index + 1}`}
                    ></button>
               ) )}
                {/* <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
            </div>
            <div class="carousel-inner">
                {images?.map((el, index) =>
                    <div className={` slider-image carousel-item ${index === 0 ? 'active' : ''}`} data-bs-interval="10000">
                        <img src={el} class="d-block w-100 " alt={index} />
                    </div>
                )}
            </div>
            {/* <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button> */}
        </div>
    )
}

export default FitnessSlider