import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  loggMeIN,
  rmAuth,
  setActiveMenu,
  toggleMenu,
} from "../../redux/Functions/actions";
import axios from "../../axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import FitnessSlider from "./fitnessSlider";
import './homepage.scss'

const HomePage = (props) => {
  const navigate = useNavigate()
  const [fitnessData, setFitnessData] = useState()

  function handleLogut() {
    navigate("/")
    window.localStorage.clear()
    props?.loggMeIN()
    props?.rmAuth()
  }
  console.log('propsa xona', props)
  function getFitnesData() {
    axios.get(`/fitness/${props?.fitness_id}`)
      .then((res) => {
        setFitnessData(res.data)
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error)
        console.error('error getting fitness data', err)
      })
  }
  useEffect(() => {
    getFitnesData();
  }, [])

// For changing colors
  // const getRandomColor = () => {
  //   const letters = '0123456789ABCDEF';
  //   let color = '#';
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // };
  // const [borderColor, setBorderColor] = useState(getRandomColor);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setBorderColor(getRandomColor());
  //   }, 2000);
  //   return () => clearInterval(interval); 
  // }, []); 
// For changing colors


  return (
    <>
      {console.log('fitnessData', fitnessData)}
      {/* <div className="data min-vh-100 d-flex align-items-center justify-content-center py-3">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="d-flex justify-content-end">
                  <div className="border border-2 rounded border-danger p-1">
                    <button type="button" className="buttons" onClick={() => handleLogut()}> Log out </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row h-100">
                    <div className="col-12">
                      <img src={`${process.env.REACT_APP_UPLOADS}${fitnessData?.logo}`} style={{ width: "25%" }} alt="" />
                    </div>
                    <div className="col-12">
                      <h6>{fitnessData?.description_al}</h6>
                      <h1>{fitnessData?.name}</h1>
                      <div className="adress">
                        <h4>{fitnessData?.city?.name}, {fitnessData?.country?.name}</h4>
                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <div className="d-flex">
                        <div className="border border-2 rounded-3 border-danger me-2 p-1">
                          <Link to="/1DayPass">
                            <button type="button" className="buttons">#1 Day Pass</button>
                          </Link>
                        </div>
                        <div className="border border-2 rounded border-danger p-1">
                          <Link to="/activateUsers">
                            <button type="button" className="buttons">Act. Users</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex  justify-content-center flex-column">
                  <FitnessSlider fitnessGallery={fitnessData?.fitness_images} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="headerIconLogOut">
        <div className="container logOutButton">
          <div className=" logoFitness">
            <img src={`${process.env.REACT_APP_UPLOADS}${fitnessData?.logo}`} style={{ width: "30%", borderRadius: '12px' }} alt="" />
          </div>
          <div className="logoutButtonBorder">
            <button onClick={() => { handleLogut() }}> Log Out  &nbsp; <i class="fa-solid fa-right-from-bracket "></i></button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="homepage-layout mt-5">
          <div className="row homepageRow">
            <div className="col-12 col-md-6 logoImageFitness mt-2">
              <div className="slider-styleImage">
                <FitnessSlider fitnessGallery={fitnessData?.fitness_images} />
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <div className="textButtons">
                <div className="col-12 mt-5 ">
                  <p className="fitness-name" >{fitnessData?.name.charAt(0).toUpperCase() + fitnessData?.name.slice(1)} </p>
                  <p className="fitness-nameCity">{fitnessData?.city?.name.charAt(0).toUpperCase() + fitnessData?.city?.name.slice(1)}, {fitnessData?.country?.name.charAt(0).toUpperCase() + fitnessData?.country?.name.slice(1)} </p>
                  {console.log("fitnessData", fitnessData)}
                </div>
                <div className="col-12 buttonsActionUsers mt-5 mb-5">
                  <Link to={'1DayPass'} className="col-6 buttonsActions ">
                    <button className="color-change-3x">
                      #1 Day <br /> Passes  &nbsp;  <i class="fa-solid fa-arrow-right-long"></i>
                    </button>
                  </Link>
                  <Link to={'activateUsers'} className="col-6 buttonsActionsU">
                    <button className="color-change-3x">
                    Activate <br /> Subscription &nbsp; <i class="fa-solid fa-arrow-right-long"></i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    lang: state.data.lang,
    fitness_id: state.data.fitness_id,
    langData: state.data.langData,
    activemenu: state.data.activemenu,
    isLoading: state.data.isLoading,
    loggedIn: state.data.loggedIn,
    menu: state.data.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loggMeIN: () => dispatch(loggMeIN()),
    rmAuth: () => dispatch(rmAuth()),
    toggleMenu: () => dispatch(toggleMenu()),
    // loadingOff: () => dispatch(loadingOff()),
    setActiveMenu: (id) => dispatch(setActiveMenu(id)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
