import React, { useState } from 'react';
import './login.scss';
import axios from '../../axios';
import companyLogo from '../../images/001-02.svg'
import ola from '../../images/gym.jpg'
import { toast } from 'react-toastify';
import { connect } from 'react-redux'
import { loggMeIN, setAuth, setFitnessId, setRole, setUserId } from '../../redux/Functions/actions';
import sign from 'jwt-encode';
import jwtDecode from 'jwt-decode';

const Login = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    async function handleLogin(e) {
        e.preventDefault();
        let secretKey = process.env.REACT_APP_SECRET
        let body = {
            email: email,
            password: sign(password, secretKey)
        }
        axios.post('/auth/fitnesslogin', body)
            .then(async (res) => {
         
                var decoded = jwtDecode(res?.data?.token);
                window.localStorage.setItem("data", res?.data?.token)
                props?.setRole(decoded?.role);
                props?.setFitnessId(decoded?.fitness_id);
                props?.setUserId(decoded?.user_id);
                props?.setAuth(res?.data?.token)
                props?.loggMeIN(true)
                console.log("decoded", decoded)
                toast.success('Logged in successfully!');

            })
            .catch((err) => {
                toast.error(err?.response?.data?.error);
            });
    }

    return (
        <div class=" h-100 ">
            <div className="container-fluid ">
                <div className='row'>
                    <div className="col-md-4 h-100 container_Body ">
                        <div class="user_card">
                            <div class="d-flex justify-content-center">
                                <div class="brand_logo_container">
                                    <img src={companyLogo} class="brand_logo" alt="Logo" />
                                </div>
                            </div>
                            <div class="d-flex justify-content-center form_container">
                                <form onSubmit={handleLogin}>
                                    <div class="input-group ol mb-3">
                                        <div class="input-group-append">
                                            <span><i class="fas fa-user mt-2" style={{ color: '#c0392b' }}></i></span>
                                        </div>
                                        <input type="text" name="" onChange={(e) => { setEmail(e.target.value) }} class="form-control input_user" placeholder="E-mail" />
                                    </div>
                                    <div class="input-group ol mb-2">
                                        <div class="input-group-append">
                                            <span class=""><i class="fas fa-key mt-2" style={{ color: '#c0392b' }}></i></span>
                                        </div>
                                        <input type="password" name="" onChange={(e) => { setPassword(e.target.value) }} class="form-control input_pass" placeholder="Password" />
                                    </div>
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <label class="custom-control-label"></label>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-center mt-3 login_container">
                                        <button type="submit" name="button" class="btn login_btn">Login</button>
                                    </div>
                                </form>
                            </div>
                            <div class="mt-2">
                                <div class="d-flex justify-content-center links">
                                    <a href="#">Forgot your password?</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='image_Container col-md-8  p-0'>
                        <img src={ola} className='' alt="" />
                    </div>
                </div>
            </div>
        </div>
    );

};

const mapStateToProps = (state) => {
    return {
        logged_in: state.logged_in,
        counter: state.counter
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        loggMeIN: (data) => dispatch(loggMeIN(data)),
        setAuth: (data) => dispatch(setAuth(data)),
        setRole: (data) => dispatch(setRole(data)),
        setUserId: (data) => dispatch(setUserId(data)),
        setFitnessId: (data) => dispatch(setFitnessId(data))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);